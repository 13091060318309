<template>
	<div class="kd-index">
			<div class="kd-top">
				<!-- 地址 -->
				<div class="kd-top-add tui-skeleton-rect">
					<div>
						<span class="iconfont icon-dizhi"></span>
						<span class="kd-top-address">重庆市渝北区</span>
					</div>
					<span class="iconfont icon-ziyuan"></span>
				</div>
				
				<!-- 天气信息 -->
				<div class="kd-top-weather" >
					<div class="kd-top-weather-li tui-skeleton-rect">17℃</div>
					<div class="kd-top-weather-li tui-skeleton-rect li-change">温度变化7℃~17℃</div>
					<div class="kd-top-weather-li tui-skeleton-rect">PM值:52</div>
					<div class="kd-top-weather-li tui-skeleton-rect">风力:微风</div>
					<div class="kd-top-weather-li tui-skeleton-rect">湿度:52%</div>
				</div>
				<!-- 商品搜索框 -->
				<div class="kd-top-search tui-skeleton-fillet">
					<span class="iconfont icon-icon15"></span>
					<span>请输入商品名称</span>
				</div>
				
				<!-- 轮播图 -->
				<div class="swiper-con">
					<div class="swiper">
						<a-carousel autoplay style="height:150px;overflow:hidden;" arrows>
							<img v-for="(item,index) in list" :key="index" :src="item.url" style="width:150px;height:150px;" />
						</a-carousel>
					</div>
				</div>
			</div>
			<div class="kd-index-bg" :style="bgColor"></div>
		</div>
</template>

<script>
	export default{
		components:{
			name:'custom-home-top'
		},
		props:{
			list:{
				type:[Object,Array]
			},
			bg:{
				type:String,
				default:""
			}
		},
		computed:{
			bgColor(){
				return `background:${this.bg};`
			},
		}
	}
</script>

<style lang="scss">
	.kd-index{
		
		height: 310px;
		background: #fff;
		
		&-bg{
			width: 140%;
			height: 250px;
			position: relative;
			left: -20%;
			top: 0;
			z-index: 0;
			overflow: hidden;
			border-radius: 0 0 50% 50%;
			background: linear-gradient(#2cbaf9, #04a9f4);
		}
	}
	
	@mixin pageWidth {
		width: 94%;
		margin-left: 3%;
	}
	@mixin viewHeight($height) {
		height: $height;
		line-height: $height;
	}
	
	// 顶部
	.kd-top{
		width: 100%;
		height: 250px;
		padding-top: 10px;
		position: absolute;
		z-index: 9;
		&-add{
			@include  pageWidth;
			@include  viewHeight(40px)
			display: flex;
			color: #fff;
			justify-content: space-between;
		}
		
		&-address{
			font-size: 16px;
			margin-left: 5px;
			font-weight: bold;
		}
		.iconfont{
			font-size: 18px;
		}
		
		// 天气变化
		&-weather{
			@include pageWidth;
			@include  viewHeight(13px);
			font-size: 12px;
			margin-top: 10px;
			color: #f9e2bf;
			display: flex;
			justify-content: space-around;
			
			&-li{
				text-align: center;
				border-right: 1px solid #f9e2bf;
				padding: 0 5px;
				white-space: nowrap;
			}
			&-li:first-child{
				font-weight: bold;
				color: #fff;
			}
			
			&-li:last-child{
				border: none;
			}
		}
		
		// 商品搜索框
		&-search{
			@include pageWidth;
			@include viewHeight(40px);
			border-radius: 5px;
			color: #fff;
			font-size: 15px;
			margin-top: 20px;
			background: rgba($color: #fff, $alpha: .2);
			.iconfont{
				margin: 0 5px 0 10px;
			}
		}
		
		// 轮播图
		.swiper-con{
			.swiper{
				@include  pageWidth;
				height: 150px;
				margin-top: 15px;
				border-radius: 5px;
				box-shadow: 1px 3px 10px #ebe9e5;
				
				img{
					width: 100%;
					height: 100%;
					border-radius: 5px;
				}
			}
			
			.swiper-dot{
				@include pageWidth;
				@include viewHeight(20px);
				display: flex;
				position: absolute;
				margin-top: -20px;
				justify-content: center;
				align-items: center;
				
				&-li{
					width: 5px;
					height: 5px;
					background: #fff;
					border-radius: 50%;
					margin: 0 2px;
				}
				
				.active{
					width: 15px;
					border-radius: 5px;
				}
			}
		}
	}
	
	
	
</style>
