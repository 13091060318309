<template>
	<!-- 物联网设备选择 -->
	<div class="com-device-select">
		<a-select
		    v-model:value="did"
		    show-search
		    option-filter-prop="children"
		    @change="changeDid"
		>
		    <a-select-option :value="0">请选择设备</a-select-option>
		    <a-select-option v-for="(item,index) in deviceData" :value="item.did" :key="index">
				{{ item.dev_alias }} (mac:{{item.mac}})
				(
					<span style="color: #00CC66;" v-if="item.is_online">在线</span>
					<span v-else>离线</span>
				)
			</a-select-option>
		</a-select>
	</div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import liveModel from '@/api/live'
export default{
	name:"com-device-select",
	props:{
		value:{
			type:[String,Number],
		},
		deviceList:{		//默认设备信息
			type:[Array,Object],
		},
	},

	setup(props,context){
		const state = reactive({
			did:props.value || 0,
			is_open_web_of_thing:0,
			deviceData:[],
		})
		
		if( !props.deviceList || props.deviceList.length == 0 ){
			liveModel.open_getThingHostList(0,true,res=>state.deviceData = res)
		}else{
			state.deviceData = props.deviceList
		}
		watch(() => props.value,(value) => {
                state.did  = value
            }
        )

		function changeDid(e){
			context.emit("change",e)
		}

		return{
			...toRefs(state),
			changeDid
		}
	}
}
</script>

<style>
</style>
