<template>
	<div class="custom-goods" v-if="infos">
		<template v-if="infos.listType == 1">
			<div class="proList" :style="{background:infos.bgColor}">
				<div  class="clearfix proList_wrapper" :style="setWidth">
					<div v-for="(item, index) in list" :key="index" class="proItem" :style="imgRadius">
						<div class="proItem_content">
							<img :src="item.cover" />
							<p :style="setTitleColor" v-text="item.goods_name" class="proTitle" v-if="infos.isShopname"></p>
							<div class="pro-item-btn">
								<div>
									<span class="price" :style="setPriceColor">￥{{item.price}}</span>
									<span class="old-price" v-if="!infos.isPaybtn">￥{{item.old_price}}</span>
								</div>
								<div v-if="infos.isPaybtn">
								    <div v-if="infos.btnstyle == 1" :style="setCarColor" class="ri-shopping-cart-2-line shopIcon"></div>
								    <div v-if="infos.btnstyle == 2" class="iconfont buy_btn" :style="btnBg">购买</div>
								    <div v-if="infos.btnstyle == 3" class="iconfont buy_btn" :style="btnBg">马上抢</div>
								    <div v-if="infos.btnstyle == 4 && infos.goodstype == 1" class="iconfont buy_salecount">已售{{getSaleCount(item.sale_count,item.true_sale)}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="infos.listType == 2">
			<div class="type_wrapper" :style="{background:infos.bgColor}">
				<div class="type_item" v-for="(item, index) in list" :key="index" :class="infos.boxshadow == 1?'boxshawd':infos.boxshadow == 2?'borderactive':''" :style="imgRadius">
                    <img :src="item.cover" />
					<p :style="setTitleColor" v-text="item.goods_name" class="proTitle" v-if="infos.isShopname"></p>
					
					<div class="tags" v-if="item.service_id">
						<template v-for="(val,ind) in item.service_id">
							<div v-if="ind < 2 " :key="ind">{{ val.name }}</div>
						</template>
					</div>
					
					<div class="buy_wrapper">
						<div v-if="infos.isPrice">
							<span :style="setPriceColor">￥{{item.price}}</span>
							<span class="old-price">￥{{item.old_price}}</span>
						</div>
                        <div v-if="infos.isPaybtn">
                            <div v-if="infos.btnstyle == 1" :style="setCarColor" class="ri-shopping-cart-2-line shopIcon"></div>
                            <div v-if="infos.btnstyle == 2" class="iconfont buy_btn" :style="btnBg">购买</div>
                            <div v-if="infos.btnstyle == 3" class="iconfont buy_btn" :style="btnBg">马上抢</div>
                            <div v-if="infos.btnstyle == 4 && infos.goodstype == 1" class="iconfont buy_salecount">已售{{getSaleCount(item.sale_count,item.true_sale)}}</div>
                        </div>
					</div>
                    <div class="new_products" v-if="infos.markerstyle == 1 && infos.isMarker">新品</div>
                    <div class="best_sellers" v-if="infos.markerstyle == 2 && infos.isMarker">热卖</div>
                    <div class="buy_new" v-if="infos.markerstyle == 3 && infos.isMarker">NEW</div>
                    <div class="buy_hot" v-if="infos.markerstyle == 4 && infos.isMarker"><p>HOT</p></div>
                    <div class="buy_hot" v-if="infos.markerstyle == 5 && infos.isMarker"><p>{{infos.markertext}}</p></div>
				</div>
			</div>
		</template>
		<template v-if="infos.listType == 3">
			<div class="types_wrapper" :style="{background:infos.bgColor}">
				<div v-for="(item, index) in list" class="types_item" :key="index" :style="imgRadius">
                    <img :src="item.cover" />
					<div class="item_info">
						<div class="goods-name" :style="setTitleColor" v-text="item.goods_name" v-if="infos.isShopname"></div>
						<div class="item_info_desc">{{item.goods_remark}}</div>
						<div class="tags" v-if="item.service_id">
							<template v-for="(val,ind) in item.service_id">
								<div v-if="ind < 2 " :key="ind">{{ val.name }}</div>
							</template>
						</div>
						<div class="buy_wrapper">
							<div v-if="infos.isPrice">
								<span :style="setPriceColor">￥{{item.price}}</span>
								<span class="old-price">￥{{item.old_price}}</span>
							</div>
							<div class="item_info_btn" v-if="infos.isPaybtn" :style="btnBg">马上抢</div>
						</div>
					</div>
                    <div class="new_products" v-if="infos.markerstyle == 1 && infos.isMarker">新品</div>
                    <div class="best_sellers" v-if="infos.markerstyle == 2 && infos.isMarker">热卖</div>
                    <div class="buy_new" v-if="infos.markerstyle == 3 && infos.isMarker">NEW</div>
                    <div class="buy_hot" v-if="infos.markerstyle == 4 && infos.isMarker"><p>HOT</p></div>
                    <div class="buy_hot" v-if="infos.markerstyle == 5 && infos.isMarker"><p>{{infos.markertext}}</p></div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
import goodsModel from '@/api/goods'
export default{
	name:'custom-goods',
	props:{
		infos:Object
	},
	setup(props){
		const state = reactive({
			list:[]
		})

		if( props.infos.goodstype == 1){
			getGoodsList(props.infos.limit)
		}else{
			state.list = props.infos.list
		}

		watch(() => props.infos.limit,(value) => {
                getGoodsList(value)
            }
        )
		watch(() => props.infos.goodstype,(value) => {
                if( value == 1 ){
					getGoodsList(props.infos.limit)
				}else{
					if( props.infos.list ){
						state.list = props.infos.list
					}else{
						state.list = []
					}
				}
            }
        )
		watch(() => props.infos.goodsSort,(value) => {
                getGoodsList(props.infos.limit)
            }
        )

		const setWidth = computed(()=>{
			if( state.list && state.list.length > 0 ){
				return `width:${state.list.length * 33.3}%`
			}
			return ""
		})
		const setTitleColor = computed(()=>{
			return `color: ${props.infos.fontColor}`
		})
		const setPriceColor = computed(()=>{
			return `color: ${props.infos.priceColor}`
		})
		const setCarColor = computed(()=>{
			return `color: ${props.infos.btnColor}`
		})
		const btnBg = computed(()=>{
			return `background: ${props.infos.btnColor}`
		})
		const imgRadius = computed(()=>{
			return `border-radius: ${props.infos.radius}px`
		})
		const getSaleCount = computed(()=>{
			return function(count1,count2){
				return parseInt(count1)+parseInt(count2)
			}
		})
		

		function getGoodsList(limit){
			let { goodsSort } = props.infos
			goodsModel.getGoodsList(1,limit ? limit:3,{is_recommend:1,sort:goodsSort,is_shop:1,audit:1,is_put_away:1},res=>{
				state.list = res.list
			})
		}

		return{
			...toRefs(state),
			getGoodsList,
			setWidth,
			setTitleColor,
			setPriceColor,
			setCarColor,
			btnBg,
			imgRadius,
			getSaleCount
		}
	}
}
</script>

<style lang="scss">
	.custom-goods{
		.pro-item-btn{
			text-align: left;
			display: flex;
			justify-content: space-between;
			
			.price{
				position: relative;
				left: 2px;
			}
		}
		.old-price{
			text-decoration: line-through;
			color: #999;
			font-size: 12px;
			margin-left: 4px;
		}
		
		.tags{
			width: 100%;
			overflow: hidden;
			display: flex;
			margin: 8px 0;
			div{
				padding: 2px 6px;
				background:#eeeeee;
				color: #999;
				margin-left: 6px;
				font-size: 12px;
			}
		}
		
	}
	
	.proList{
	    width: 100%;
	    background: #f4f4f4;
	    overflow-x: auto;
	    padding-top: 5px;
	}
	.proList_wrapper{
	    display: flex;
	    align-items: center;
	    min-width: 100%;
		
	}
	
	.proList .proItem{
	    width: 33.3%;
	    height: 100%;
	    padding: 0 5px;
	    box-sizing: border-box;
	    overflow: hidden;
	}
	.proItem_content{
	    background: #fff;
	}
	.proList .proItem img{
	    width: 100%;
	    height: 113px;
	    border-radius: 10px;
	}

	.proTitle{
	    font-size: 14px;
	    line-height: 18px;
	    height: 41px;
	    width: 100%;
	    overflow:hidden;
	    text-overflow:ellipsis;
	    display:-webkit-box;
	    -webkit-line-clamp:2;
	    -webkit-box-orient:vertical;
	    text-align: left;
	    margin: 0;
	    padding: 5px 5px 0px 5px;
	    box-sizing: border-box;
	}
	.buy_wrapper{
	    width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	    height: 20px;
	    font-size: 13px;
	    margin-top: 5px;
	    padding: 0 5px;
	    box-sizing: border-box;
	}

    .new_products{
        position: absolute;
        top: 5px;
        left: 0;
        padding: 2px 6px;
        border-radius: 0 30px 30px 0;
        background: red;
        color: #fff;
        font-size: 12px;
    }

    .best_sellers{
        position: absolute;
        top: 0;
        left: 0px;
        width: 22px;
        background: red;
        color: #fff;
        font-size: 12px;
        padding: 1px 0 4px;
    }

    .buy_new{
        position: absolute;
        top: 0;
        left: 5px;
        background: red;
        color: #fff;
        font-size: 12px;
        padding: 4px;
        border-radius: 0 0 6px 6px;
    }

    .buy_hot{
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
    }

    .buy_hot>p{
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -9px;
        width: 30px;
        color: #fff;
        z-index: 9;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
    }

    .buy_hot:before{
        content: '';
        position: absolute;
        top: 7px;
        left: 7px;
        width: 30px;
        height: 30px;
        background: red;
        z-index: 0;
    }
    .buy_hot::after{
        content: '';
        position: absolute;
        top: 7px;
        left: 7px;
        width: 30px;
        height: 30px;
        background: red;
        transform: rotate(45deg);
        z-index: 0;
    }

    .buy_styleimg{
        position: absolute;
        top: 0;
        left: 0px;
    }
    .buy_styleimg>img{
        width: 50px !important;
        height: 50px !important;
    }

    .buy_btn{
        width: 50px;
        height: 24px;
        border-radius: 12.5px;
        text-align: center;
        line-height: 24px;
        color: #fff;
        font-size: 12px;
        margin-bottom: 4px;
    }

    .buy_salecount{
        font-size: 12px;
        color: #999;
    }

	.shopIcon{
	    font-size: 22px;
	}

	.loading_img{
	    width: 100px;
	    height: 100px;
	    position: fixed;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%,-50%);
	    z-index: 2200;
	}
	.shopList_wrapper{
	    height: 700px;
	    background: #ffffff;
	    z-index: 2000;
	    position: fixed;
	    top: 120px;
	    left: 50%;
	    border-radius: 10px;
	    transition: all .3s;
	    transform: scale(0) translateX(-50%);
	    font-size: 12px;
	    color: #333;
	}
	.shopList_wrapper_title{
	    font-size: 14px;
	    width: 100%;
	    padding: 0px 10px;
	    line-height: 35px;
	    box-sizing: border-box;
	    text-align: center;
	    position: relative;
	}
	.shopList_wrapper_content{
	    width: 100%;
	    height: 620px;
	    padding: 5px 10px 0px 20px;
	    box-sizing: border-box;
	    display: flex;
	    align-items: center;
	    flex-wrap: wrap;
	    background: #f4f4f4;
	    overflow-y: auto;
	}
	.shopList_wrapper_item{
	    width: 24%;
	    background: #ffffff;
	    margin-bottom: 5px;
	    overflow: hidden;
	    border-radius: 5px;
	    margin-right: 1%;
	    cursor: pointer;
	    overflow: hidden;
	}
	.shopList_wrapper_item:hover{
	    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
	}
	.shopList_wrapper_item img{
	    width: 100%;
	}
	.shopList_wrapper_price{
	    font-size: 12px;
	    padding: 5px;
	    box-sizing: border-box;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
	}
	.shopList_wrapper_price .select_div{
	    display: block;
	    width: 20px;
	    height: 20px;
	    background: #1A97EE;
	    border-radius: 50%;
	    text-align: center;
	    line-height: 20px;
	    color: #fff;
	    font-size: 14px;
	}
	.shopList_wrapper_footer{
	    width: 100%;
	    height: 45px;
	    text-align: center;
	    font-size: 14px;
	    line-height: 45px;
	}
	.shopList_wrapper_footer span{
	    padding: 5px 20px;
	    border-radius: 5px;
	    border: 1px solid #efefef;
	    cursor: pointer;
	}

	.type_wrapper{
	    display: flex;
	    align-items: center;
	    flex-wrap: wrap;
	    width: 100%;
	    padding:5px 0px 0px 3px;
	    box-sizing: border-box;
	    background: #f4f4f4;
	    overflow: hidden;
        box-sizing: border-box;
	}
	.type_wrapper .type_item{
	    width:  48%;
	    height: 280px;
	    /* border-radius: 5px; */
	    background: #fff;
	    overflow: hidden;
	    margin: 0 3px 5px 0px;
        position: relative;
	}

    .boxshawd{
        box-shadow: 0 0 18px 0 #eee;
    }

    .borderactive{
        border: 1px solid #eee;
    }

	.type_wrapper .type_item img{
	    width: 100%;
	    height: 170px;
	}

	.type_wrappers{
	    display: flex;
	    align-items: center;
	    flex-wrap: wrap;
	    width: 100%;
	    padding:5px 0px 0px 5px;
	    box-sizing: border-box;
	    background: #f4f4f4;
	    overflow: hidden;
	}
	.type_wrappers .type_items{
	    width:  32%;
	    height: 180px;
	    border-radius: 5px;
	    background: #fff;
	    overflow: hidden;
	    margin: 0 4.5px 5px 0px;
	}
	.type_wrappers .type_items img{
	    width: 100%;
	    height: 111px;
	}

	.types_wrapper{
	    width: 100%;
	    background: #f4f4f4;
	    overflow: hidden;
	}
	.types_wrapper .types_item{
		background: #fff;
		width: 96%;
		margin: 6px auto;
		/* border-radius: 5px; */
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		overflow: hidden;
		padding: 10px 8px;
        position: relative;
	}
	.types_wrapper .types_item img{
	    width: 100px;
	    height: 100px;
	    border-radius: 5px;
	}
	.types_wrapper .types_item .item_info{
	    flex: 1;
	    margin-left: 20px;
	    font-size: 14px;
		width: 62%;
	}
	.types_wrapper .types_item .item_info .goods-name{
	    font-size: 14px;
	    line-height: 25px;
	    width: 100%;
	    overflow:hidden;
	    text-overflow:ellipsis;
		white-space: nowrap;
	    text-align: left;
	}
	.types_wrapper .types_item .item_info .item_info_desc{
	    font-size: 12px;
	    line-height: 25px;
	    width: 100%;
		color: #999;
	    overflow:hidden;
	    text-overflow:ellipsis;
		white-space: nowrap;
	    text-align: left;
	    margin-bottom: 10px;
	}
	.types_wrapper .types_item .item_info .item_info_btn{
	    width: 60px;
	    height: 25px;
	    border-radius: 12.5px;
	    text-align: center;
	    line-height: 25px;
	    color: #fff;
	    font-size: 14px;
	}
</style>
