<template>
    <div class="kd-coupon">
        <div class="kd-coupon-title">
            <span class="title">{{infos.title}}</span>
            <div class="right" v-if="infos.is_more">
                更多<span class="iconfont icon-left-angle"></span>
            </div>
        </div>
        <!-- 排版样式n/卡片样式一  -->
        <div class="kd-coupon_ul" v-if="infos.list && infos.couponarray == 1 && (infos.couponcard == 1 || infos.couponcard == 4)">
            <div class="kd-coupon_ul_transverse" :class="infos.couponcard == 4?'borderradius':''" :style="infos.couponcolor == 1?couponBg(item.bg1,item.bg2):couponBgcolor(item.bg2)" v-for="(item,index) in infos.list" :key="index">
                <div style="display: flex;align-items: center;">
                    <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                    <div style="text-align: left; font-size: 13px;">
                        <div class="item-title">优惠券</div>
                        <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                    </div>
                </div>
                <div class="btn">领取</div>
                <div class="cycle">
                    <div class="cycle-li" v-for="val in 20" :key="val"></div>
                </div>
                <div class="couponafter" v-if="infos.couponcard != 4">
                    <div class="couponafter-li" v-for="val in 20" :key="val"></div>
                </div>
                <div class="couponbefore" v-if="infos.couponcard != 4">
                    <div class="couponbefore-li" v-for="val in 20" :key="val"></div>
                </div>
            </div>
        </div>
        <div class="kd-coupon_ul" :class="infos.couponarray == 2?'couponflex': infos.couponarray == 4?'couponovfx':''" v-if="infos.list && (infos.couponarray == 2 || infos.couponarray == 4) && (infos.couponcard == 1 || infos.couponcard == 4)">
            <div class="kd-coupon_ul_transverse2" :class="infos.couponcard == 4?'borderradius':''" :style="infos.couponcolor == 1?couponBg(item.bg1,item.bg2):couponBgcolor(item.bg2)" v-for="(item,index) in infos.list" :key="index">
                <div style="width: 95px; text-align: center;">
                    <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                    <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                </div>
                <div class="btn">领取</div>
                <div class="couponafter" v-if="infos.couponcard != 4">
                    <div class="couponafter-li" v-for="val in 20" :key="val"></div>
                </div>
                <div class="couponbefore" v-if="infos.couponcard != 4">
                    <div class="couponbefore-li" v-for="val in 20" :key="val"></div>
                </div>
            </div>
        </div>
        <div class="kd-coupon-ul" style="display: flex;justify-content: space-between;flex-wrap: wrap;" v-if="infos.list && infos.couponarray == 3 && (infos.couponcard == 1 || infos.couponcard == 4)">
            <div class="kd-coupon-ul-item" :class="infos.couponcard == 4?'borderradius':''" :style="infos.couponcolor == 1?couponBg(item.bg1,item.bg2):couponBgcolor(item.bg2)" v-for="(item,index) in infos.list" :key="index">
                <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                <div class="item-title">优惠券</div>
                <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                <div class="btn" :style="{color:item.bg2}">领取</div>
                <div class="couponafter" v-if="infos.couponcard != 4">
                    <div class="couponafter-li" v-for="val in 20" :key="val"></div>
                </div>
                <div class="couponbefore" v-if="infos.couponcard != 4">
                    <div class="couponbefore-li" v-for="val in 20" :key="val"></div>
                </div>
            </div>
        </div>

        <!-- 排版样式n/卡片样式二  -->
        <div class="kd-coupon_ul" v-if="infos.list && infos.couponarray == 1 && infos.couponcard == 2">
            <div class="kd-coupon_ul_border" :style="infos.couponcolor == 1?couponBg(item.bg1,item.bg2):couponBgcolor(item.bg2)" v-for="(item,index) in infos.list" :key="index">
                <div :style="couponBor(infos.lineColor)">
                    <div :style="couponBor(infos.lineColor)">
                        <div style="display: flex;align-items: center;">
                            <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                            <div style="text-align: left; font-size: 13px;">
                                <div class="item-title">优惠券</div>
                                <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                            </div>
                        </div>
                        <div class="btn">领取</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kd-coupon_ul border1" v-if="infos.list && infos.couponarray == 2 && infos.couponcard == 2">
            <div class="kd-coupon_ul_border1" :style="infos.couponcolor == 1?couponBg(item.bg1,item.bg2):couponBgcolor(item.bg2)" v-for="(item,index) in infos.list" :key="index">
                <div :style="couponBor(infos.lineColor)">
                    <div :style="couponBor(infos.lineColor)">
                        <div>
                            <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                            <div style="text-align: left; font-size: 12px;text-align: center;padding-left: 4px;">
                                <div class="item-title">优惠券</div>
                                <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                            </div>
                        </div>
                        <div class="btn">领取</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kd-coupon_ul" :class="infos.couponarray == 3?'couponflex': infos.couponarray == 4?'couponovfx':''" v-if="infos.list && (infos.couponarray == 3 || infos.couponarray == 4) && infos.couponcard == 2">
            <div class="kd-coupon_ul_border2" :style="infos.couponcolor == 1?couponBg(item.bg1,item.bg2):couponBgcolor(item.bg2)" v-for="(item,index) in infos.list" :key="index">
                <div :style="couponBor(infos.lineColor)">
                    <div :style="couponBor(infos.lineColor)">
                        <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                        <div style="text-align: left; font-size: 12px;text-align: center;padding-left: 4px;">
                            <div class="item-title">优惠券</div>
                            <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                        </div>
                        <div class="btn" :style="{color:item.bg2}">领取</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 排版样式n/卡片样式三 -->
        <div class="kd-coupon_ul" v-if="infos.list && infos.couponarray == 1 && infos.couponcard == 3">
            <div class="kd-coupon_ul_img" v-for="(item,index) in infos.list" :key="index">
                <img src="@/assets/img/coupona.png" alt="">
                <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                <div class="coupon_cont">
                    <div class="item-title">优惠券</div>
                    <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                </div>
                <div class="btn">领取</div>
            </div>
        </div>
        <div class="kd-coupon_ul" :class="infos.couponarray == 3?'couponflex': infos.couponarray == 4?'couponovfx':''" v-if="infos.list && (infos.couponarray == 3 || infos.couponarray == 4) && infos.couponcard == 3">
            <div class="kd-coupon_ul_img1" v-for="(item,index) in infos.list" :key="index">
                <img src="@/assets/img/couponb.png" alt="">
                <div class="price"><span>￥</span><span>{{item.coupon_price}}</span></div>
                <div class="coupon_cont">
                    <div class="item-title">优惠券</div>
                    <div class="desc">{{couponType(item.type)}}- 满{{item.low_cash_price}}使用</div>
                </div>
                <div class="btn">领取</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'custom-coupon',
    props:{
        infos:{
            type:Object
        }
    },
    computed:{
        couponBg(){
            return function(color1,color2){
                return `background:linear-gradient(to bottom, ${color1},  ${color2});`
            }
        },
        couponBgcolor(){
            return function(color2){
                return `background:${color2};`
            }
        },
        couponBor(){
            return function(lineColor){
                return `border:1px solid ${lineColor};`
            }
        },
        couponType(){
            return function(type){
                if( type == 1 ){
                    return '商城'
                }
                if( type == 3 ){
                    return '土地'
                }
                if( type == 4 ){
                    return '认养'
                }
                return '通用'
            }
        },
    },
    methods:{

    }
}
</script>
<style lang="scss" scoped>
//领券专区
.kd-coupon{
    width: 100%;
    height: auto;
    background: #fff;
    padding: 10px 0;

    &-title{
        width: 90%;
        margin-left: 5%;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: space-between;

        .title{
            font-size: 16px;
            font-weight: bold
        }
        .right{
            font-size: 12px;
            color: #868686;

            .iconfont{
                font-size: 12px;
            }
        }
    }

    &-ul{
        width: 100%;
        box-sizing: border-box;
        white-space: nowrap;
        margin-top: 5px;

        &-item{
            width: 32%;
            height: 120px;
            font-size:12px;
            color: #fff;
            text-align: center;
            position: relative;
            margin-bottom: 14px;
            .item-title{
                height: 20px;
                line-height: 20px;
                width: 100%;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 3px;
            }
            .price{
                margin: 10px 0 0;

                span:first-child{
                    font-size: 12px;
                }
                span:last-child{
                    font-size: 22px;
                }
            }
            .btn{
                width: 30px;
                padding: 2px 15px;
                height: 20px;
                line-height: 20px;
                border-radius: 30px;
                background: rgba($color: #fff, $alpha: 0.9);
                margin:10px auto;
            }
            .couponafter{
                position: absolute;
                top: -1px;
                left: -8px;

                &-li{
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    border-radius: 50%;
                }
            }
            .couponbefore{
                position: absolute;
                top: -1px;
                right: -8px;

                &-li{
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    border-radius: 50%;
                }
            }
        }

        .bg1{
            background: linear-gradient(to right,#21e100,#01ce00);
        }
        .bg2{
            background: linear-gradient(to right,#c38dff,#4f53ff);
        }
        .bg3{
            background: linear-gradient(to right,#ffc524,#ff4b1b);
        }
        .color1{
            color: #01ce00;
        }
        .color2{
            color: #4f53ff;
        }
        .color3{
            color: #ff4b1b;
        }
    }

    &_ul{
        width: 93%;
        margin: auto;
        color: #fff;

        &_transverse{
            margin-bottom: 10px;
            display: flex;
            height: 80px;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .price{
                font-size: 27px;
                margin-left: 20px;
                margin-right: 10px;
            }

            .item-title{
                margin-bottom: 6px;
            }

            .btn{
                margin-right: 30px;
            }

            .cycle{
                width: 80px;
                height: 2px;
                top: 38px;
                right: 15%;
                position: absolute;
                display: flex;
                justify-content: space-between;
                transform: rotate(90deg);

                &-li{
                    width: 4px;
                    height: 2px;
                    background: #fff;
                    border-radius: 50%;
                }
                &-li:first-child{
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    margin: -4px 0 0 -5px;
                }
                &-li:last-child{
                    width:10px;
                    height: 10px;
                    position: absolute;
                    margin: -4px 0 0 77px;
                }
            }

            .couponafter{
                position: absolute;
                top: -1px;
                left: -8px;

                &-li{
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    border-radius: 50%;
                }
            }
            .couponbefore{
                position: absolute;
                top: -1px;
                right: -8px;

                &-li{
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    border-radius: 50%;
                }
            }
        }

        &_transverse2{
            width: 42%;
            display: flex;
            justify-content: space-between;
            position: relative;
            height: 80px;
            align-items: center;
            text-align: center;
            padding: 0 10px;
            margin: 0 0 10px;
            margin-right: 5px;

            .price{
                font-size: 27px;
            }
            .desc{
                font-size: 13px;
            }
            .btn{
                width: 25px;
                font-size: 14px;
                margin-left: 30px;
            }
            .couponafter{
                position: absolute;
                top: -1px;
                left: -8px;

                &-li{
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    border-radius: 50%;
                }
            }
            .couponbefore{
                position: absolute;
                top: -1px;
                right: -8px;

                &-li{
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    border-radius: 50%;
                }
            }
        }

        &_border{
            margin-bottom: 10px;
            height: 80px;
            position: relative;
            overflow: hidden;

            >div{
                height: 79px;

                >div{
                    width: 96%;
                    margin:6px auto 0;
                    border: 1px solid #E7DFDF;
                    height: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .price{
                width: 75px;
                font-size: 27px;
                margin-right: 50px;
            }

            .item-title{
                margin-bottom: 6px;
            }

            .btn{
                height: 90%;
                margin-right: 30px;
                padding-left: 30px;
                border-left: 1px dashed #eee;
                line-height: 65px;
            }

        }

        &_border1{
            margin-bottom: 10px;
            height: 90px;
            position: relative;
            width: 48%;

            >div{
                height: 89px;

                >div{
                    width: 92%;
                    margin:6px auto 0;
                    border: 1px solid #E7DFDF;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align: center;
                }

                .price{
                    width: 75px;
                    font-size: 27px;
                }

                .btn{
                    height: 90%;
                    padding-left: 10px;
                    padding-right: 10px;
                    border-left: 1px dashed #eee;
                    line-height: 65px;
                }
            }
        }

        &_border2{
            margin-bottom: 10px;
            height: 120px;
            position: relative;
            width: 30%;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;

            >div{
                height: 119px;
                width: 100%;

                >div{
                    width: 87%;
                    margin:6px auto 0;
                    border: 1px solid #E7DFDF;
                    height: 105px;
                }

                .price{
                    font-size: 27px;
                    text-align: center;
                }

                .btn{
                    width: 30px;
                    padding: 2px 15px;
                    height: 20px;
                    line-height: 20px;
                    border-radius: 30px;
                    background: rgba($color: #fff, $alpha: 0.9);
                    margin:10px auto;
                    font-size: 13px;
                }
            }
        }

        &_img{
            width: 100%;
            height: 80px;
            margin-bottom: 15px;
            position: relative;
            color: red;

            >img{
                width: 100%;
                height: 80px;
            }

            .price{
                position: absolute;
                top: 25%;
                left: 9%;
                font-size: 27px;
                font-weight: bold;
            }

            .coupon_cont{
                position: absolute;
                top: 25%;
                left: 42%;
                color: #fff;
                text-align: left;
            }

            .btn{
                position: absolute;
                top: 25%;
                right: 5%;
                width: 30px;
                padding: 2px 15px;
                height: 20px;
                line-height: 20px;
                border-radius: 30px;
                background: rgba($color: #fff, $alpha: 0.9);
                margin:10px auto;
                font-size: 13px;
            }
        }

        &_img1{
            width: 30%;
            margin-bottom: 15px;
            position: relative;
            color: red;
            margin-right: 10px;

            >img{
                width: 100%;
                height: 125px;
            }

            .price{
                width: 60px;
                position: absolute;
                top: 7%;
                left: 50%;
                margin-left: -30px;
                font-size: 25px;
                font-weight: bold;
            }

            .coupon_cont{
                position: absolute;
                top: 45%;
                left: 9%;
                color: #fff;
                text-align: center;
                font-size: 13px;
            }

            .btn{
                position: absolute;
                top: 67%;
                right: 20%;
                width: 30px;
                padding: 2px 15px;
                height: 20px;
                line-height: 20px;
                border-radius: 30px;
                background: rgba($color: #fff, $alpha: 0.9);
                margin:10px auto;
                font-size: 13px;
            }
        }
    }

    .border1{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        // height: 80px;
        align-items: center;
    }

    .couponovfx{
        display: flex;
        display: -webkit-box;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
    }

    .borderradius{
        border-radius: 10px;
    }

    .couponflex{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }
}
</style>