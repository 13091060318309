<template>
    <div class="kd-stores">
        <div class="kd-stores-title">
            <span class="title">{{infos.title}}</span>
            <div class="right" v-if="infos.is_more">
                更多<span class="iconfont icon-left-angle"></span>
            </div>
        </div>
        <div class="kd-stores-nav" v-if="infos.store_style == 3">
            <div class="kd-stores-nav-item" @click="store_type=1" data-current="1">
                <span :style="store_type==1?activeColor:''">商品</span>
            </div>
            <div class="kd-stores-nav-item" @click="store_type=2" data-current="2">
                <span :style="store_type==2?activeColor:''">店铺</span>
            </div>
        </div>

        <div class="kd-stores-goods" v-if="store_type==1 && storeList.length>0">
            <div class="kd-stores-goods-item" v-for="(item,index) in storeList" :key="index">
                <div class="kd-stores-goods-item-top">
                    <div class="left">
                        <img :src="item.logo" alt="">
                        <span class="name">{{item.name}}</span>
                    </div>
                    <div class="care" v-if="infos.is_care ==1"><span class="ri-heart-2-line ri-lg ri-top"></span>收藏</div>
                    <div class="care" v-if="infos.is_care ==2">进店逛逛</div>
                </div>
                <div class="kd-stores-goods-item-center">
                    <div class="ul" v-for="item in 3" :key="item">
                        <img src="@/assets/img/cover.jpg" alt="">
                        <div class="goods-name">商品名称【动态数据】</div>
                        <div class="price">￥59.80</div>
                    </div>
                </div>
                <div class="kd-stores-goods-item-footer">点击查看更多低价商品>></div>
            </div>
        </div>
        <div v-if="store_type==2">
            <div class="kd-stores-item" v-for="(item,index) in storeList" :key="index" >
                <img :src="item.logo" class="cover" alt="">
                <div class="kd-stores-item-right">
                    <div class="name">{{item.name}}</div>
                    <div class="rate">
                        <span class="iconfont icon-shoucang"></span>
                        <span class="iconfont icon-shoucang"></span>
                        <span class="iconfont icon-shoucang"></span>
                        <span class="iconfont icon-shoucang"></span>
                        <span class="iconfont icon-shoucang"></span>
                        <span>5.0分</span>
                    </div>
                    <div class="btm">
                        <span>距离：12 km</span>
                    </div>
                    <div class="phone">
                        <span class="ri-phone-line ri-lg"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, reactive, toRefs,watch } from 'vue'
import shopModel from '@/api/addons/shop';
export default {
    name:'custom-store',
    props:{
        infos:{
            type:[Object,Array,String]
        }
    },
    mounted(){
        let t = this
        t.getShopList(t.infos.limit);
        if( t.infos.store_style ){
            if( t.infos.store_style == 1 ){
                t.store_type = 1
            }else{
                t.store_type = 2
            }
        }
    },
    setup(props,context){
        const state = reactive({
            storeList:[],
            store_type:1
        })

        const activeColor = computed(()=>{
            return `color:${props.infos.active_color};border-bottom:3px solid ${props.infos.active_color}`;
        })

        watch(() => props.infos.limit,(value) => {
                getShopList(value)
            }
        )
        watch(() => props.infos.store_style,(value) => {
                if( value == 1 ) state.store_type = 1
                if( value == 2 ) state.store_type = 2
            }
        )

        function changeCurrent(){
            context.emit('changeCurrent',0);
        }

        function getShopList(limit){
            shopModel.getShop(1,limit,null,res=>state.storeList = res.list)
        }

        return{
            ...toRefs(state),
            changeCurrent,
            getShopList,
            activeColor
        }
    }

}
</script>
<style lang="scss" scoped>
//店铺展示
.kd-stores{
    width: 100%;
    width: 90%;
    margin-left: 5%;
    background: #FFFFFF;
    margin-top: 15px;
    padding: 10px 0;

    &-title{
        width: 100%;
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        .title{
            font-weight: bold;
            font-size: 16px;
        }
        .right{
            font-size: 12px;
            color: #868686;
        }
    }

    &-nav{
       width: 100%;
        height: 40px;
        line-height: 40px;
        background: #fafafa;
        display: flex;
        margin-top: 10px;

        &-item{
            width: 50%;
            text-align: center;
            font-size: 14px;
            color: #95a0af;
            span{
                height: 37px;
                display: inline-block;
            }
        }
    }


    &-goods{
        width: 100%;
        padding-bottom: 15px;

        &-item{
            width: 100%;
            height: 225px;
            border-radius: 5px;
            box-shadow: 1px 5px 10px #efefef;
            padding: 0 3%;
            margin-top: 14px;

            &-top{
                width: 100%;
                height: 50px;
                line-height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img{
                    width:30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .left{
                    display: flex;
                    align-items: center;
                }
                .name{
                    font-weight: bold;
                    font-size: 16px;
                }

                .care{
                    height: 25px;
                    line-height: 25px;
                    padding: 0 10px;
                    border-radius: 25px;
                    background: red;
                    color: #fff;
                    font-size: 12px;

                    .iconfont {
                        font-size: 14px;
                        margin-right: 3px;
                    }
                }
            }

            &-center{
                width: 100%;
                height: 140px;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;

                .ul{
                    width: 31%;
                    height: 100%;

                    img{
                        width: 100%;
                        height: 88px;
                        border-radius: 7px;
                    }

                    .goods-name{
                        width: 100%;
                        font-size: 13px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .price{
                        font-size: 14px;
                        margin-top: 5px;
                        color: #dd252e;
                        font-weight: bold;
                    }
                }
            }

            &-footer{
                height: 23px;
                line-height: 23px;
                text-align: center;
                color: #252525;
                font-size: 12px;
                border-top: 1px solid #f4f4f4;
            }
        }

    }


    &-item{
        width: 100%;
        height: 100px;
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #f1f3f4;

        .cover{
            width: 80px;
            height: 80px;
            border-radius: 5px;
        }

        &-right{
            width:70%;
            margin-left: 2%;
            text-align: left;

            .name{
                height: 30px;
                line-height: 30px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 15px;
                font-weight: bold;
            }
            .rate{
                height: 30px;
                line-height: 30px;
                width: 100%;
                color: #f6ac1d;
                font-size: 13px;

                .iconfont{
                    font-size: 15px !important;
                    margin-right: 2px;
                }
            }
            .btm{
                color: #868686;
                font-size: 12px;
                text{
                    margin-right:20px;
                }
            }
            .phone{
                height: 35px;
                line-height: 35px;
                width: 35px;
                text-align: center;
                border-radius: 50%;
                background: rgba($color: #f5f5f5, $alpha: 1);
                position: absolute;
                margin:-44px 0 56%;
                color: #f6ac1d;
                right: 2%;
            }
        }
    }
}
</style>