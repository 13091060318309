<template>
	<div class="kd-hotarea">
		<div class="kd-hotarea-img" :style="hotHeight" draggable="false"  @mousedown="getMouseDowm" @mousemove="getMove" @mouseup="getMonseLeave">
			<div :style="getHotStyle(item)" v-for="(item,index) in list" :key="index" >
				{{index+1}}
			</div>
			<div v-if="draw" :style="getHotStyle(init)"></div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'custom-hot-area',
		data(){
			return{
				//热区个数
				list:[],	
				init:{
					x:0,
					y:0,
					w:0,
					h:0,
					path:'',
					jump:1,
					appid:'',
					mini_path:''
				},
				px:0,
				py:0,
				draw:false
			}
		},
		props:{
			infos:{
				type:Object,
				value:{}
			},
			showDraw:{	//是否可以绘画
				type:Boolean,
				default:false
			}
		},
		computed:{
			getHotStyle(){
				return function(e){
					console.log(e);
					return `width:${e.w}px;height:${e.h}px;left:${e.x}px;top:${e.y}px;`
				}
			},
			hotHeight(){
				return `height:${this.infos.height}px;background-image: url('${this.infos.cover}');background-repeat: no-repeat;background-size:contain`
			}
		},
		created() {
			if( this.infos.list && this.infos.list.length > 0 ){
				this.list = this.infos.list
			}
		},
		methods:{
			getMouseDowm(e){
				if( this.showDraw ){
					this.init.x = e.offsetX
					this.init.y = e.offsetY
					this.px = e.pageX
					this.py = e.pageY
					this.draw = true
				}
			},
			
			getMove(e){
				let t = this
				if( this.draw ) {
					this.init.w = Math.abs(e.pageX-this.px)
					this.init.h = Math.abs(e.pageY-this.py)
				}
			},
			
			getMonseLeave(e){
				let t = this ,p = this.init
				if( t.showDraw ){
					this.init.w = Math.abs(e.pageX-this.px)
					this.init.h = Math.abs(e.pageY-this.py)
					this.list.push({
						x:p.x,
						y:p.y,
						w:Math.abs(e.pageX -t.px),
						h:Math.abs(e.pageY - t.py),
						path:'',
						jump:1,
						appid:'',
						mini_path:'',
					})
					this.draw = false
					t.init = { x:0,y:0,w:0,h:0,path:'',jump:1,appid:'',mini_path:''}
					this.$emit('finishDraw',t.list)
				}
			}
		},
		
	}
</script>

<style lang="scss">
	.kd-hotarea{
		.kd-hotarea-img{
			cursor: auto;
			width: 100%;
			min-height: 20px;
			position: relative;
			div{
				border: 1px solid #177CE3;
				position: absolute;
				background: rgba(#177CE3,.2);
			}
		}
	}
</style>
