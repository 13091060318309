<template>
<div>
    <!-- 导航栏 -->
    <div class="kd-custom" :style="computedAttrs.homeTopStyle" v-if="tempType=='kd-nab-bar'" @click="$emit('changeCurrent',0)">
        <div class="kd-back-icon" v-if="infos.isNavBack">
            <i class="ri-arrow-left-s-line ri-lg ri-top"></i>
        </div>
        <div class="kd-content">{{infos.title}}</div>
    </div>

    <!-- 轮播图 -->
    <div class="kd-slide" :style="computedAttrs.slideHeight" v-if="tempType=='slide'">
        <a-carousel :dots="infos.focus">
            <div :style="computedAttrs.slideHeight" v-for="(item,index) in infos.list" :key="index">
                <img style="width:100%;" :style="computedAttrs.slideHeight" :src="item.url"/>
            </div>
        </a-carousel>
    </div>
    <!-- 辅助空白 -->
    <div class="blank" :style="computedAttrs.blankstyle" v-if="tempType=='blank'"></div>

    <!-- 图片列表 -->
    <div class="photo" v-if="tempType=='photo'">
        <div :class="['photo-style'+infos.styles,'photo-column'+infos.column]" v-for="(item,index) in infos.list" :key="index">
            <img :src="item.url" :style="computedAttrs.photoRadio" class="photo-column-img">
            <div class="photo-title" v-if="infos.styles!=3">{{item.title}}</div>
        </div>
    </div>
    <!-- 分割线 -->
    <div v-if="tempType == 'lines'" class="line" :style="computedAttrs.lineStyle">
        <div :style="computedAttrs.lineHrStyle"></div>
    </div>

    <!-- 标题 -->
    <div v-if="tempType == 'headline'" class="headline" :style="computedAttrs.headlineStyle">
        <span class="ri-top ri-lg" :class="infos.icon"></span>{{infos.text}}
    </div>

    <!-- 商品卡片 -->
    <div v-if="tempType == 'kd-goods-card'" class="kd-card">
        <div class="kd-card-li">
            <div class="kd-card-title">{{infos.title}}</div>
            <div class="kd-card-item">
                <div class="kd-card-item-top">
                    <img :src="infos.bg" alt="">
                </div>
                <div class="ul">
                    <div class="li" v-for="(item,index) in infos.list" :key="index">
                        <img  v-if="item.cover" :src="item.cover" alt="">
                        <div class="goods-name">{{item.goods_name}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="tempType == 'weather'" class="weather" style="height:80px;">
		<img style="width:100%" :src="infos.url" />
	</div>
    <div v-if="tempType == 'kd-official'" class="official" style="width: 100%;height: 84px;">
		<img style="width:100%" :src="infos.url" />
	</div>
    <div v-if="tempType == 'kd-device'" style="width: 100%;height: 200px;">
        <img style="width:100%" :src="infos.url" />
    </div>
    <div v-if="tempType == 'kd-video'" class="kd-assist-video">
        <video :src="infos.url" :poster="infos.cover" controls></video>
    </div>

    <!-- 监控组件 -->
    <div v-if="tempType == 'kd-assist-one'" class="kd-ll">
        <div class="kd-ll-land">
            <div class="kd-ll-land-title">{{infos.title_one}}</div>
            <div>
                <div class="kd-ll-land-one" :style="{background:infos.list[0].bg}">{{infos.list[0].name}}</div>
                <div class="kd-ll-land-btm">
                    <div class="kd-ll-land-btm-li" :style="{background:infos.list[1].bg}">{{infos.list[1].name}}</div>
                    <div class="kd-ll-land-btm-li" :style="{background:infos.list[2].bg}">{{infos.list[2].name}}</div>
                </div>
            </div>
        </div>
        <div class="kd-ll-live">
            <div class="kd-ll-land-title">{{infos.title_two}}</div>
            <div class="kd-ll-live-ul">
                <div class="kd-ll-live-ul-item" v-for="(item,index) in live" :key="index">
                    <img  class="cover" :src="item.cover" alt="">
                    <div class="player"><span class="ri-play-fill ri-lg ri-top"></span></div>
                </div>
            </div>
        </div>
    </div>

    <!-- 宫格导航 -->
    <div v-if="tempType=='navigation'" class="zyl-navigation" :style="{background:infos.bgColor}">
        <template v-for="(item, index) in infos.list" :key="index">
            <div class="zyl-navigation_item"  v-if="index < infos.column * 2" :style="computedAttrs.navWidth">
                <img class="zyl-navigation_item_img" :style="computedAttrs.navImgRadius" :src="item.url" />
                <div class="zyl-navigation_item_txt" :style="computedAttrs.navFontStyle"  >{{item.title}}</div>
            </div>
        </template>
	</div>

    <!-- 图文集 -->
    <div v-if="tempType=='glossarys'" class="glossarys">
        <div v-for="(item, index) in infos.list" :key="index" class="itemWrapper" :style="{background:infos.bgColor}">
            <div v-if="infos.styles == 1">
                <img :src="item.url" class="style1_img" />
                <div class="style1_content">
                    <div v-text="item.title" :style="computedAttrs.glossarysTitle" class="glossary_title"></div>
                    <div class="glossary_innerText" v-text="item.innerText"></div>
                </div>
            </div>
            <div v-if="infos.styles == 2">
                <img :src="item.url" class="style2_img" />
                <div v-text=" item.title" :style="computedAttrs.glossarysTitle" class="glossary_title"></div>
                <div class="glossary_innerText" v-text="item.innerText"></div>
            </div>
        </div>
	</div>

    <!-- 资讯 -->
    <div v-if="tempType=='information'" class="kd-infomation">
        <div class="kd-infomation-left">
            <img :src="infos.icon_url" alt="">
        </div>
        <div class="kd-infomation-right">
            <div class="kd-infomation-right-li">
                <div class="li-title" :style="{background:infos.title_one_color}">{{infos.title_one}}</div>
                <div class="li-value">
                    <a-carousel class="li-value_content" direction="vertical" :autoplay="true" v-if="infos.list &&infos.list.length>0">
                        <template v-for="(item,index) in infos.list">
                            <div :key="index" v-if="index%2==0">
                                <div class="kd-content_medium">{{item.title}}</div>
                            </div>
                        </template>
                    </a-carousel>
                </div>
            </div>
            <div class="kd-infomation-right-li">
                <div class="li-title" :style="{background:infos.title_two_color}">{{infos.title_two}}</div>
                <div class="li-value">
                    <a-carousel class="li-value_content" v-if="infos.list">
                        <template v-for="(item,index) in infos.list">
                            <div  :key="index" v-if="index%2!=0">
                                <div class="kd-content_medium">{{item.title}}</div>
                            </div>
                        </template>
                    </a-carousel>
                </div>
            </div>
        </div>
    </div>

    <!-- 广告位 -->
    <div v-if="tempType=='advert'" class="advert" :style="{background:infos.bgColor}">
		<template v-if="infos.selectType == 1">
			<div class="advert_typeOne" v-for="(item, index) in infos.list" :key="index">
                <img :src="item.url" style="width:100%;height:100%;"/>
            </div>
		</template>
		<template v-if="infos.selectType == 2">
			<div class="advert_typeTwo">
				<div class="advert_typeTwo_item" v-for="(item, index) in infos.list" :key="index">
                    <img :src="item.url" style="width:100%;height:100%;" />
                </div>
			</div>
		</template>
		<template v-if="infos.selectType == 3">
			<div class="advert_typeThr"  v-if="infos.list.length == 3 ">
				<div class="advert_typeThr_mian"><img :src="infos.list[0].url" style="width:100%;height:100%;" /></div>
				<div class="advert_typeThr_sub">
					<div class="advert_typeThr_subItem"><img :src="infos.list[1].url" style="width:100%;height:100%;" /></div>
					<div class="advert_typeThr_subItem"><img :src="infos.list[2].url" style="width:100%;height:100%;" /></div>
				</div>
			</div>
		</template>
		<template v-if="infos.selectType == 4">
			<div class="advert_typeOne"><img :src="infos.list[0].url" style="width:100%;height:100%;" /></div>
			<div class="advert_typeFour"  v-if="infos.list.length == 4 ">
                <template v-for="(item, index) in infos.list">
                    <div :key="index"  v-if="index > 0 && index <= 3" class="advert_typeFour_item">
                        <img :src="item.url" style="width:100%;height:100%;" />
                    </div>
                </template>
			</div>
		</template>

		<template  v-if="infos.selectType == 5">
			<div class="advert_typefive"  v-if="infos.list.length == 4 ">
				<div class="advert_typefive_left">
					<img :src="infos.list[0].url" style="width:100%;height:100%;" />
				</div>
				<div class="advert_typefive_right">
					<div class="advert_typefive_right_top">
						<img :src="infos.list[1].url" style="width:100%;height:100%;" />
					</div>
					<div class="advert_typefive_right_btm">
						<div class="advert_typefive_right_btm_subItem">
							<img :src="infos.list[2].url" style="width:100%;height:100%;" />
						</div>
						<div class="advert_typefive_right_btm_subItem">
							<img :src="infos.list[3].url" style="width:100%;height:100%;" />
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>

    <!-- 弹出层广告 -->
    <div v-if="tempType == 'kd-popup-advert'" class="popup-advert">
        <div class="pa-mask"></div>
        <img class="pa-img" :src="infos.cover" alt="">
    </div>

</div>
</template>
<script>
import { computed, getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import liveModel from '@/api/live';
export default{
  name:"model-item",
    props:{
        tempType:{
            type:String,
            default:"",
        },
        infos:{
            type:[Object,Array],
            default:{}
        },
    },
    emits:['changeCurrent'],
    setup(props) {
        const t = getCurrentInstance().proxy
        const computedAttrs = reactive({
            //hometop 导航栏
            homeTopStyle:computed(()=>{
                return `background:${props.infos.bgColor};color:${props.infos.fontColor}`
            }),
            //轮播图高度
            slideHeight:computed(()=>{
                return `height:${props.infos.swiperHeight}px`
            }),
            //辅助空白样式
            blankstyle:computed(()=>{
                return `background: ${props.infos.bgColor}; height:${props.infos.height}px;width:100%;`
            }),
            photoRadio:computed(()=>{
                return `border-radius:${props.infos.radius}%`
            }),
            lineStyle:computed(()=>{
                return `height: ${props.infos.height}px; background: ${props.infos.bgColor}`
            }),
            lineHrStyle:computed(()=>{
                return `border-top: ${props.infos.lineHeight}px ${props.infos.styles} ${props.infos.lineColor}`
            }),
            headlineStyle:computed(()=>{
                return `font-size:${props.infos.fontSize}px;
                        color:${props.infos.fontColor};
                        background:${props.infos.bgColor};
                        text-align: ${props.infos.align};
                        padding: ${props.infos.padTb}px ${props.infos.padLr}px`
            }),
            navImgRadius:computed(()=>{
                return `border-radius: ${props.infos.radius}%`
            }),
            navFontStyle:computed(()=>{
                return `font-size:${props.infos.fontSize}px; color:${props.infos.fontColor}`
            }),
            navWidth:computed(()=>{
                const column = props.infos.column
				let width = 0
				if (column === 2) width = 50
				else if (column === 3) width = 33.3
				else if (column === 4) width = 25
				else width = 20
				return `width:${width}%;`
            }),
            glossarysTitle:computed(()=>{
                return `fontSize: ${props.infos.titleSize}px; color:${props.infos.titleColor}`
            })
        })

        const data = reactive({
            live:[],
        })
        onMounted:{
            if(props.tempType == 'kd-assist-one'){
                liveModel.getLiveList(1,4,null,res=>data.live = res.list)
            }
        }

        return{
            computedAttrs,
            ...toRefs(data)
        }
    },
}

</script>
<style lang="scss" scoped>
    .kd-custom{
        width: 100%;
        height: 50px;
        background: #999;
        text-align: center;
        line-height: 50px;
        cursor: pointer;

        .kd-back-icon{
            position: absolute;
        }
        .kd-content{
            font-weight: bold;
            font-size: 16px;
        }
    }

    // 图片组件
    .photo{
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &-column1{
            width: 100%;
        }
        &-column2{
            width: 50%;
        }
        &-column3{
            width: 33%;
        }
        &-column4{
            width: 25%;
        }

        &-column-img{
            width: 100%;
        }

        .photo-title{
            width: 100%;
            text-align: center;
            height: 32px;
            line-height: 32px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .photo-style2{
            position: relative;
            .photo-title{
                background: rgba($color: #000000, $alpha: .2);
                color: #fff;
                position: absolute;
                margin-top: -32px;
                font-size: 12px;
            }
        }
    }

    // 商品卡片
	.kd-card{
		width: 100%;
		background: #fff;
        padding: 12px;
		&-li{
			margin-bottom: 15px;
		}

		&-title{
            font-weight: bold;
            font-size: 16px;
            width: 100%;
			height: 30px;
			text-align: left;
		}

		&-item{
			width: 100%;
			margin-top: 10px;
			border-radius: 5px;
			box-shadow: 0 4px 10px #eaeaea;

			&-top{
				width: 100%;
				height: 50px;

				img{
					width: 100%;
					height: 50px;
				}
			}

			.ul{
				margin-top: 10px;
				display: flex;
				justify-content: space-between;

				.li{
					width: 30%;
					height: 115px;
					font-size: 12px;
					text-align: center;

					img{
						width: 70px;
						height: 70px;
						border-radius: 50%;
					}
					.goods-name{
						height: 20px;
						width: 90%;
						margin: auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}
    .kd-assist-video{
		width: 100%;
		height: 210px;

		video{
			width: 100%;
			height: 100%;
		}
	}

    // 土地+监控组件
	.kd-ll{
		width: 100%;
		margin-left: 3%;
		background: #fff;
		height: 190px;
		margin-top: 15px;
		display: flex;
		justify-content: space-between;


		&-land{
			width: 45%;
			font-weight: bold;

			&-title{
                font-weight: bold;
				text-align: left;
                font-size: 16px;
			}

			&-one{
				height: 75px;
				width: 100%;
				border-radius: 5px;
				background: #007AFF;
				text-align: center;
				margin-top: 10px;
				color: #fff;
			}

			&-btm{
				margin-top: 5px;
				display: flex;
				color: #fff;
				justify-content: space-between;
				&-li{
					width: 48%;
					height: 75px;
					background: #4CD964;
					border-radius: 5px;
					text-align: center;
				}
			}
		}

		&-live{
			width: 53%;
			margin-left: 2%;

			&-ul{
				display: flex;
				margin-top:10px;
				flex-wrap: wrap;
				justify-content: space-around;

				&-item{
					width: 88px;
					height: 75px;
					margin-bottom: 5px;
					background:#000;
					z-index: 999;
					border-radius: 5px;

					.cover{
						width: 100%;
						height: 100%;
						border-radius: 5px;
						opacity: 0.5;
					}

					.player{
                        height: 25px;
						width: 25px;
						border-radius: 50%;
						background: rgba($color: #fff, $alpha: 0.3);
						position: absolute;
						margin: -52px 0 0 27px;
						text-align: center;

						span {
							color: #fff;
							font-size: 12px;
							position: relative;
							top: -3px;
							left: 1px;
						}
					}
				}
			}
		}
	}

    .zyl-navigation{
		width: 100%;
		padding-bottom: 10px;
		position: relative;
		display: flex;
		flex-wrap: wrap;

		&_item{
			padding-top: 5px;
            text-align: center;
			box-sizing: border-box;

			&_img{
				width: 56px;
				height: 56px;
			}
		}
	}

    .glossarys {
		width: 100%;
		text-align: left;

        .itemWrapper {
            padding: 5px;
            box-sizing: border-box;
            width: 100%;
            display: flex;
        }

        .style1_img {
            width: 100px;
            height: 100px;
            float: left;
        }

        .style1_content {
            float: left;
            width: 255px;
            height: 80px;
            padding-left: 10px;
            box-sizing: border-box;
        }

        .glossary_title {
            margin-top: 10px;
            width: 86%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 700;
        }

        .glossary_innerText {
            font-size: 12px;
            margin-top: 10px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .style2_img {
            width: 100%;
        }
	}

    // 资讯
	.kd-infomation{
		width: 94%;
        margin-left: 3%;
		height: 50px;
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		&-left{
			width: 16%;
			border-right: 1px solid #e1e6ec;

			img{
				width: 40px;
				height: 40px;
				margin-top: 5px;
			}
		}

		&-right{
			width: 82%;
			margin-left: 2%;

			&-li{
                height: 25px;
                line-height: 25px;
				display: flex;
				align-items: center;

				.li-title{
                    height: 18px;
                    line-height: 18px;
					padding: 0 9px;
					color: #fff;
					border-radius: 5px;
					margin-right: 10px;
				}

				.li-value{
					width: 79%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: #72788c;

					.li-value_content{
						height: 25px;
                        line-height: 25px;
					}
					.kd-content_medium{
						text-align: left;
					}
				}
			}
		}
	}

    // 广告位
    .advert {
		width: 100%;
		overflow: hidden;

        &_typefive{
            width: 100%;
            height: 160px;
            display: flex;
            justify-content: space-between;
            padding: 10px 3% 10px 3%;

            &_left{
                width: 40%;
                height: 100%;
            }
            &_right{
                width: 58%;
                height: 100%;

                &_top{
                    width: 100%;
                    height: 60px;
                    padding-bottom: 5px;
                }
                &_btm{
                    width: 100%;
                    height: 80px;
                    display: flex;
                    justify-content: space-between;
                    &_subItem{
                        width: 48%;
                        height: 100%;
                    }
                }
            }
            img{
                border-radius: 5px;
            }
        }

        .advert_typeOne:not(:last-child) {
            margin-bottom: 10px;
        }

        .advert_typeOne {
            width: 96%;
            height: 150px;
            border-radius: 7px;
            box-shadow: 0 0 5px rgba(0, 0, 0, .1);
            overflow: hidden;
            margin: 0 auto;
        }
        .advert_typeFour {
            width: 96%;
            height: 150px;
            display: flex;
            margin: 10px auto 0;
        }
        .advert_typeFour_item:not(:last-child) {
            margin-right: 2%;
        }
        .advert_typeFour_item {
            width: 32%;
            height: 100%;
            border-radius: 5px;
            overflow: hidden;
        }
        .advert_typeThr {
            width: 100%;
            height: 250px;
            padding: 5px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
        }
        .advert_typeThr_sub {
            width: 49%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .advert_typeThr_subItem {
            width: 100%;
            height: 49%;
            border-radius: 5px;
            overflow: hidden;
        }
        .advert_typeThr_mian {
            width: 49%;
            height: 100%;
            border-radius: 7px;
            overflow: hidden;
        }
        .advert_typeTwo {
            width: 100%;
            padding: 0 5px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .advert_typeTwo_item {
            width: 49%;
            height: 176px;
            border-radius: 7px;
            overflow: hidden;
            margin-bottom: 8px;
        }
	}

    //弹出层广告
    .popup-advert{
        .pa-mask{
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: .5);
        }
        .pa-img{
            width: 100%;
        }
    }



</style>
